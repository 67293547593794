import Vue from 'vue'
import Router from 'vue-router'
import AuthRequired from '@/utils/AuthRequired'

Vue.use(Router)

const routes = [
    {
        path: '/warehouse',
        component: () => import('@/website/backend'),
        redirect: '/warehouse',
        beforeEnter: AuthRequired,
        children: [
            {
                path: '/order', component: () => import('@/website/backend/dashboard'),
                meta: { title: 'รายการสินค้า' }
            },
            {
                path: '/orderstatus', component: () => import('@/website/backend/order/index_new'),
                meta: { title: 'เช็คสถานะพัสดุ' }
            },
            {
                path: '/check-order', component: () => import('@/website/backend/order/checkOrder'),
                meta: { title: 'ตรวจสอบออเดอร์' }
            },
            {
                path: '/order-detail', component: () => import('@/website/backend/order/order-detail'),
                meta: { title: 'รายละเอียดออเดอร์' }
            },
            {
                path: '/delivery-note', component: () => import('@/website/backend/deliveryNote'),
                meta: { title: 'ใบส่งสินค้า' }
            },
            {
                path: '/delivery-note/detail', component: () => import('@/website/backend/deliveryNote/detail'),
                meta: { title: 'ใบส่งสินค้า' }
            },
            {
                path: '/my-product', component: () => import('@/website/backend/stock'),
                meta: { title: 'สินค้าของฉัน' }
            },
            {
                path: '/my-product/update', component: () => import('@/website/backend/stock/addstock'),
                meta: { title: 'เพิ่มสินค้า' }
            },
            {
                path: '/my-product/update/excel', component: () => import('@/website/backend/stock/addstock_excel'),
                meta: { title: 'เพิ่มสินค้า excel' }
            },
            {
                path: '/my-stock', component: () => import('@/website/backend/stock/allstock'),
                meta: { title: 'สต๊อกสินค้า' }
            },
            {
                path: '/lot-stock', component: () => import('@/website/backend/stock/lotstock'),
                meta: { title: 'ล้อตสินค้า' }
            },
            {
                path: '/report-check-stock', component: () => import('@/website/backend/stock/reportCheckStock'),
                meta: { title: 'รายงานการเช็คสต๊อก' }
            },
            {
                path: '/stock-info', component: () => import('@/website/backend/stock/stock-info'),
                meta: { title: 'Stock' }
            },
            {
                path: '/transfer-list', component: () => import('@/website/backend/stock/import_master'),
                meta: { title: 'นำเข้า/นำออก/ตีกลับสินค้า' }
            },
            {
                path: '/transfer-list/update', component: () => import('@/website/backend/stock/import'),
                meta: { title: '' }
            }, 
            {
                path: '/finance', component: () => import('@/website/backend/finance'),
                meta: { title: 'ใบแจ้งหนี้' }
            },
            {
                path: '/expenses', component: () => import('@/website/backend/finance/expenses'),
                meta: { title: 'ค่าใช้จ่าย' }
            },
            {
                path: '/create-invoice', component: () => import('@/website/backend/finance/create_invoice'),
                meta: { title: 'สร้างใบแจ้งหนี้' }
            },
            {
                path: '/all-invoice', component: () => import('@/website/backend/finance/all_invoice'),
                meta: { title: 'ใบแจ้งหนี้ทั้งหมด' }
            },
            {
                path: '/invoice-detail', component: () => import('@/website/backend/finance/invoice_detail'),
                meta: { title: 'รายละเอียดใบแจ้งหนี้' }
            },
            {
                path: '/topup', component: () => import('@/website/backend/topup'),
                meta: { title: 'แจ้งการโอนเงิน' }
            },
            {
                path: '/cod-report', component: () => import('@/website/backend/codReport'),
                meta: { title: 'COD Report' }
            },
            {
                path: '/withdraw-cod', component: () => import('@/website/backend/codWithdraw'),
                meta: { title: 'แจ้งถอน COD' }
            },
            {
                path: '/manageshop', component: () => import('@/website/backend/manageShop'),
                meta: { title: 'จัดการร้านค้า' }
            },
            {
                path: '/manageshop/create', component: () => import('@/website/backend/manageShop/createShop'),
                meta: { title: 'สร้างร้านค้า' }
            },
            {
                path: '/shop-balance', component: () => import('@/website/backend/manageShop/notifyTransfer'),
                meta: { title: 'ประวัติการเงิน' }
            }, 
            {
                path: '/manageuser', component: () => import('@/website/backend/manageUser'),
                meta: { title: 'จัดการผู้ใช้' }
            },
            {
                path: '/defaultexpress', component: () => import('@/website/backend/defaultExpress'),
                meta: { title: 'ค่าขนส่งมาตรฐาน' }
            },
            {
                path: '/packcostexpress', component: () => import('@/website/backend/packcostExpress'),
                meta: { title: 'DefaultPackCost' }
            },
            {
                path: '/dashboard', component: () => import('@/website/backend/notifyExpress/flash'),
                meta: { title: 'Dashboard' }
            },
            {
                path: '/update_tracking', component: () => import('@/website/backend/editTrackingNo'),
                meta: { title: 'update_tracking' }
            },
            {
                path: '/setting', component: () => import('@/website/backend/generalSetting'),
                meta: { title: 'ตั้งค่าทั่วไป' }
            },
         
            {
                path: '/branch_balance', component: () => import('@/website/backend/branchFinance/branch_balance'),
                meta: { title: 'รายการ balance' }
            },
            {
                path: '/branch_topup', component: () => import('@/website/backend/branchFinance/branch_topup'),
                meta: { title: 'แจ้งชำระเงิน' }
            },
            {
                path: '/branch_invoice', component: () => import('@/website/backend/branchFinance/branch_invoice'),
                meta: { title: 'ใบแจ้งหนี้' }
            },
            {
                path: '/branch-view-detail-invoice', component: () => import('@/website/backend/branchFinance/view_detail_invoice'),
                meta: { title: 'รายละเอียดใบแจ้งหนี้' }
            },
            {
                path: '/branch-view-detail-invoice-express', component: () => import('@/website/backend/branchFinance/view_detail_invoice_express'),
                meta: { title: 'รายละเอียดใบแจ้งหนี้' }
            },
            {
                path: '/branch-invoice-detail', component: () => import(/* webpackChunkName: "manageuser" */ '@/website/backend/branchFinance/invoice_detail'),
                meta: { title: 'ใบแจ้งหนี้เลขที่' }
            },
            {
                path: '/ts', component: () => import(/* webpackChunkName: "ts" */ '@/website/backend/ts'),
                meta: { title: 'Ts' }
            },
            {
                path: '/create-bill', component: () => import('@/website/backend/finance/create_bill.vue'),
                meta: { title: 'สร้างใบแจ้งหนี้' }
            },
            {
                path: '/create-group-bill', component: () => import('@/website/backend/finance/create_group_bill.vue'),
                meta: { title: 'ใบแจ้งหนี้ที่ยังไม่สร้าง' }
            },
            {
                path: '/report-order', component: () => import('@/website/backend/dashboard/reportorder.vue'),
                meta: { title: 'รายงาน' }
            },
            {
                path: '/dashboard-statistics', component: () => import('@/website/backend/dashboard/statistics.vue'),
                meta: { title: 'Dashboard' }
            },
            {
                path: '/shop-space', component: () => import('@/website/backend/shopSpace'),
                meta: { title: 'รายงานใช้พื้นที่' }
            },
            {
                path: '/subscription/package', component: () => import('@/website/backend/subscription/package'),
                meta: { title: 'PACKHAI แพ็คเกจ' }
            },
            {
                path: '/location-manage', component: () => import('@/website/backend/location'),
                meta: { title: 'จัดการข้อมูล location' }
            },
            {
                path: '/user-statistics', component: () => import('@/website/backend/manageUser/userStatics'),
                meta: { title: 'สถิติ' }
            },
            {
                path: '/product-pack-list', component: () => import('@/website/backend/order/product-pack-list'),
                meta: { title: 'รายการแพ็คสินค้า' }
            },
            {
                path: '/stock-report', component: () => import('@/website/backend/stock/stock-report'),
                meta: { title: 'ภาพรวมการเดินสต๊อก' }
            },
        ]
    },
    {
        path: '/delivery-note/print', component: () => import('@/website/backend/deliveryNote/print'),
        meta: { title: 'ใบส่งสินค้า ปริ้น' }
    },
    {
        path: '/print-importstock', component: () => import('@/website/components/printImportStock'),
        meta: { title: 'ปริ้น ใบนำสินค้าเข้าคลัง' }
    },
    {
        path: '/print-barcode', component: () => import('@/website/components/printBarcode'),
        meta: { title: 'ปริ้น Barcode รายละเอียดสินค้า' }
    },
    {
        path: '/print-invoice', component: () => import('@/website/backend/finance/printNew.vue'),
        meta: { title: 'ปริ้นใบแจ้งหนี้' }
    },
    {
        path: '/print-invoice-bill', component: () => import('@/website/backend/finance/printBillNew.vue'),
        meta: { title: 'ปริ้นใบแจ้งหนี้' }
    },
    {
        path: '/print-branch-invoice', component: () => import('@/website/backend/branchFinance/printNew.vue'),
        meta: { title: 'ปริ้นใบแจ้งหนี้' }
    },
    {
        path: '/print-pricequotation', component: () => import('@/website/backend/manageShop/priceQuotation.vue'),
        meta: { title: 'ปริ้นใบเสนอราคา' }
    },
    {
        path: '/error', component: () => import('@/website/Error'),
        meta: { title: 'error' }
    },
    {
        path: '*', component: () => import('@/website/Login'),
        meta: { title: 'Login : เข้าสู่ระบบ PACKHAI Warehouse' }
    },
    {
        path: '/login', component: () => import('@/website/Login'),
        meta: { title: 'Login : เข้าสู่ระบบ PACKHAI Warehouse' }
    },
    {
        path: '/loginOasys', component: () => import('@/website/LoginOasys'),
        meta: { title: 'Login : เข้าสู่ระบบ Oasys WMS' }
    },
    {
        path: '/check-status-best=:token', component: () => import('@/website/backend/checkStatusBest'),
        meta: { title: 'checkStatusBest' }
    },
    {
        path: '/check-status-best', component: () => import('@/website/backend/checkStatusBest'),
        meta: { title: 'checkStatusBest' }
    },
    {
        path: '/customer-login', component: () => import('@/website/backend/clientCheckStatusBest/Login'),
        meta: { title: 'customerLogin' }
    },
    {
        path: '/client-check-status-best', component: () => import('@/website/backend/clientCheckStatusBest'),
        meta: { title: 'clientCheckStatusBest' }
    },
    {
        path: '/customer-report', component: () => import('@/website/backend/clientCheckStatusBest/cust_report'),
        meta: { title: 'customerReport' }
    },
    {
        path: '/gotopackage', component: () => import('@/website/gotopackage'),
        meta: { title: 'ซื้อแพ็คเกจ' }
    },
    {
        path: '/package', component: () => import('@/website/package'),
        meta: { title: 'PACKHAI แพ็คเกจ' }
    },
    {
        path: '/my-stock/printBarcode', component: () => import('@/website/backend/stock/printBarcode' ),
        meta: { title: 'PACKHAI แพ็คเกจ' }
    },
    {
        path: '/my-stock/printProductDetails', component: () => import('@/website/backend/stock/printProductDetails'),
        meta: { title: 'PACKHAI แพ็คเกจ' }
    },
    {
        path: '/printPackingList', component: () => import('@/website/backend/order/printPackingList'),
        meta: { title: 'พิมพ์ใบ PackingList' }
    },
    {    
        path: '/print-awb', component: () => import('@/website/backend/order/printawb'),
        meta: { title: 'พิมพ์ใบ Aws' }
    },
    {    
        path: '/wmsRegister', component: () => import('@/website/backend/WmsRegister'),
        meta: { title: 'ลงทะเบียน WMS' }
    },
    {
        path: '/connectGoogleDrive', component: () => import('@/website/backend/generalSetting/googleLogin.vue'),
        meta: { title: 'ตั้งค่าทั่วไป' }
    },
    {
        path: '/order-packing', component: () => import('@/website/backend/order/packing'),
        meta: { title: 'รายละเอียดออเดอร์' }
    },
]

const router = new Router({
    routes,
    mode: 'history',
    linkActiveClass: 'active'
})

router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title;
    }
    next();
});

export default router
